






















































































import { Component, Ref, Mixins } from 'vue-property-decorator';
import TransformComponent from '@common-src/mixins/transform-component';
import ThingsTemplateService from '@common-src/service/things-template2';
import FormComponent from '@common-src/mixins/form-component';
import { FacilityAttributeEntityModel } from '@common-src/entity-model/facility-attribute-entity';
import { facilityAttributeDataTypeFilter } from '@common-src/filter';
import { FacilityV2QueryModel } from '@common-src/entity-model/facility-v2-entity';
import SignService from '@common-src/service/sign-v2';
import { ThingsSyncQueryModel, ThingsSyncTemplateEntityModel } from '@common-src/entity-model/things-template-entity';
import { FormControlType } from '@common-src/model/form-control';
import ThingsSyncPreviewDialog from './things-sync-preview-dialog.vue';
import { SyncType, ThingsEntityType, ThingsFunctionType, GroupEntityType } from '@common-src/model/enum';
import { thingsModelFunctionList } from '@common-src/filter/things-model-function';
import ThingsGroupService from '@common-src/service/things-group';
import { login, getPublicKey, getVerifyPicture } from '@common-src/service3/user/user';
import { generateUUID } from '@common-src/utils/base_util';
import _JSEncrypt from '@common-src/utils/jsencrypt.min';
import { message } from 'ant-design-vue';

@Component({
    components: {
        'things-sync-preview-dialog': ThingsSyncPreviewDialog
    }
})
export default class ThingsSyncDialog extends TransformComponent {
    @Ref('remoteForm')
    jtlForm: FormComponent<any>;
    debounceReload: Function;
    treeGroupOptions: Array<any> = [];
    thingsFunctionType: ThingsFunctionType;
    SyncType = SyncType;
    sourceType: string = 'local';
    versionSame: any = null;
    ThingsSyncTemplateEntityModel: ThingsSyncTemplateEntityModel;
    loading: boolean = false;
    selectedRowKeys: Array<string> = [];
    code: string = '';
    verifyPicture: any = '';
    uuid = generateUUID();
    pagination: any = {
        pageSize: 10,
        total: 0,
        current: 1,
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: total => `共: ${total} 项`,
        pageSizeOptions: ['10', '20', '50', '100', '500']
    };

    get TableColumns() {
        return ThingsSyncTemplateEntityModel.getTableColumns();
    }

    get IsTemplate() {
        return _.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.TEMPLATE;
    }

    get ListData() {
        return this.listData;
    }

    get ThingsModelFunctionList() {
        return _.take(thingsModelFunctionList, 3);
    }

    get CurrentSyncType() {
        return _.get(this.queryModel, 'syncType');
    }

    get ThingsTypeTitle() {
        let title = null;
        if (_.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.TEMPLATE) {
            title = '产品模板';
        } else if (_.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.FUNCTION) {
            title = '物功能';
        }
        return title;
    }

    get DialogTitle() {
        let dialogTitle = null;
        let uploadOrDownloadText = null;
        if (_.get(this.queryModel, 'syncType') === SyncType.UPLOAD) {
            uploadOrDownloadText = '上传';
        } else {
            uploadOrDownloadText = '下载';
        }
        if (_.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.TEMPLATE) {
            dialogTitle = '产品模板' + uploadOrDownloadText;
        } else if (_.get(this.queryModel, 'thingsEntityType') === ThingsEntityType.FUNCTION) {
            dialogTitle = '物功能' + uploadOrDownloadText;
            switch (this.thingsFunctionType) {
                case ThingsFunctionType.ATTRIBUTE:
                    dialogTitle += '-属性';
                    break;
                case ThingsFunctionType.EVENT:
                    dialogTitle += '-事件';
                    break;
                case ThingsFunctionType.METHOD:
                    dialogTitle += '-服务';
                    break;
            }
        }
        return dialogTitle;
    }

    created() {
        this.debounceReload = _.debounce(this.reloadClick.bind(this), 500);
        // this.isAutoQuery = false;
        // this.pageSize = 999;
        this.queryModel = new ThingsSyncQueryModel();
    }

    refreshVerifyPicture() {
        this.verifyPicture = getVerifyPicture(this.uuid);
    }

    dialogOpen(syncType: SyncType, thingsEntityType: ThingsEntityType, thingsFunctionType?: ThingsFunctionType) {
        this.versionSame = undefined;
        this.thingsFunctionType = thingsFunctionType;
        this.queryModel = new ThingsSyncQueryModel();
        this.queryModel.syncType = syncType;
        this.queryModel.thingsEntityType = thingsEntityType;
        if (this.queryModel.thingsEntityType === ThingsEntityType.FUNCTION) {
            this.queryModel.sourceGroupIds = thingsFunctionType;
        }
        this.dialogVisible = true;
        this.refreshVerifyPicture();
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems: Array<any> = [
                    {
                        key: 'url',
                        label: '云端地址',
                        type: FormControlType.TEXT,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' },
                        required: true
                    },
                    {
                        key: 'username',
                        label: '云端账号',
                        type: FormControlType.TEXT,
                        required: true,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' }
                    },
                    {
                        key: 'password',
                        label: '云端密码',
                        type: FormControlType.TEXT,
                        inputType: 'password',
                        required: true,
                        layout: { labelCol: 2, wrapperCol: 18, width: '100%' }
                    }
                ];
                // if (thingsEntityType === ThingsEntityType.TEMPLATE) {
                //     controlItems.push({
                //         key: 'targetGroup',
                //         label: '目标分组',
                //         type: FormControlType.TREE_SELECT,
                //         required: false,
                //         optionsPromise: ThingsGroupService.getGroupTree,
                //         optionsPromiseParam: GroupEntityType.DEVICE_TYPE,
                //         disabled: false,
                //         layout: { labelCol: 2, wrapperCol: 18, width: '100%' }
                //     });
                // }
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
        this.localClick();
    }

    dialogOK() {
        if (!this.jtlForm) {
            return;
        }

        return new Promise((resolve, reject) => {
            const confirm = this.$confirm({
                title: '确认同步',
                onOk: () => {
                    if (this.sourceType === 'local') {
                        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
                            this.showMessageWarning('请选择要同步的数据');
                            reject('请选择要同步的数据');
                            return;
                        }
                        (this.$refs.thingsSyncPriviewDialog as ThingsSyncPreviewDialog).dialogOpen(this.sourceType === 'local', this.jtlForm.formModel.url, this.thingsFunctionType, this.queryModel, this.selectedRowKeys);
                    } else {
                        this.jtlForm.submitForm().then((ret) => {
                            if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
                                this.showMessageWarning('请选择要同步的数据');
                                reject('请选择要同步的数据');
                                return;
                            }

                            (this.$refs.thingsSyncPriviewDialog as ThingsSyncPreviewDialog).dialogOpen(this.sourceType === 'local', this.jtlForm.formModel.url, this.thingsFunctionType, this.queryModel, this.selectedRowKeys);
                        }).catch(err => {
                            reject();
                            throw err;
                        });
                    }
                },
                onCancel: () => {
                    confirm.destroy();
                }
            });
        });
    }

    syncSuccess() {
        this.queryModel.targetName = undefined;
        this.versionSame = undefined;
        this.selectedRowKeys = [];
        this.pagination.current = 1;
        this.reloadClick();
        this.$emit('dialogOK');
        // this.dialogClose();
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = null;
        // this.queryModel = null;
        this.dialogVisible = false;
        this.code = '';
        this.sourceType = 'local';
    }

    sourceChange() {
        this.listData = [];
        this.queryModel.name = '';
        this.queryModel.templateGroupIds = [];
        this.pagination.current = 1;
        this.pagination.total = 0;
        this.pagination.pageSize = 10;
        this.treeGroupOptions = [];
        this.selectedRowKeys = [];

        if (this.sourceType === 'local') {
            this.localClick();
        } else {
            if (!_.isEmpty(this.queryModel.token)) {
                this.reloadClick();
            }
        }
    }

    tableChange(paginationData:any) {
        this.pagination.pageSize = paginationData.pageSize;
        this.pagination.current = paginationData.current;
        this.initTable();
    }

    /**
     * 数据选项为本地时，请求地址获得数据
     */
    localClick() {
        this.initTable();
        this.initTreeGroup();
    }

    initTreeGroup() {
        ThingsTemplateService.getTemplateGroups({
            local: this.sourceType === 'local',
            url: this.sourceType === 'local' ? null : this.queryModel.url,
            token: this.sourceType === 'local' ? null : this.queryModel.token
        }).then(res => {
            this.treeGroupOptions = res || [];
        });
    }

    initTable() {
        this.loading = true;
        const body = {
            page: this.pagination.current,
            limit: this.pagination.pageSize,
            params: {
                templateGroupIds: this.queryModel.templateGroupIds,
                versionSame: this.versionSame || null,
                local: this.sourceType === 'local',
                url: this.sourceType === 'local' ? null : this.queryModel.url,
                token: this.sourceType === 'local' ? null : this.queryModel.token,
                templateName: this.queryModel.targetName || null
            }
        };

        ThingsTemplateService.getSyncTemplateCheck(body).then(res => {
            this.listData = res.items;
            this.pagination.total = res.Total;
        }).finally(() => {
            this.loading = false;
        });
    }

    async loginClick() {
        if (this.queryModel.token) {
            this.listData = [];
            this.rightDataList = [];
            this.queryModel.token = null;
            this.queryModel.difference = false;
            // this.queryModel = new ThingsSyncQueryModel();
            _.forEach(this.jtlForm.formControlList, item => {
                item.disabled = false;
            });
            this.refreshVerifyPicture();
            this.code = '';
            return;
        }
        let validateResult = false;
        try {
            validateResult = await this.jtlForm.validate();
        } catch (e) {
            return false;
        }

        if (_.isEmpty(this.code)) {
            this.showMessageWarning('请输入验证码');
            return false;
        }
        if (validateResult) {
            const { url } = this.jtlForm.formModel;
            const remoteUrl = url.endsWith('/') ? url.slice(0, -1) : url;
            // const remoteUrl = 'http://172.21.82.166:8080';
            const params = {
                username: this.jtlForm.formModel.username,
                password: this.jtlForm.formModel.password,
                verifyCode: this.code,
                session: this.uuid,
                device: 'PC'
            };

            try {
                const { publicKey } = await getPublicKey();
                params.username = this.encrypt(params.username, publicKey);
                params.password = this.encrypt(params.password, publicKey);
            } catch (error) {
                console.log(error);
                return;
            }

            return SignService.remoteLogin(remoteUrl, params)
                .then(res => {
                    message.success('连接成功');
                    this.queryModel.token = _.get(res, 'token');
                    this.queryModel.url = this.jtlForm.formModel.url;
                    _.forEach(this.jtlForm.formControlList, item => {
                        item.disabled = true;
                    });
                    this.reloadClick();
                    this.initTreeGroup();
                })
                .catch(err => {
                    this.refreshVerifyPicture();
                    this.code = '';
                    throw err;
                });
        }
    }

    encrypt(txt, publicKey) {
        // 加密方法
        const encryptor = new _JSEncrypt();
        encryptor.setPublicKey(publicKey);
        return encryptor.encrypt(txt);
    }

    searchHandlerClick() {
        this.pagination.current = 1;
        this.debounceReload(null, false);
    }

    reloadClick() {
        this.initTable();
    }
}

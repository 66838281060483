
import { ThingsSyncQueryModel, ThingsTemplateEntityModel, ThingsTemplateQueryModel } from '@common-src/entity-model/things-template-entity';
import { SyncType, ThingsEntityType, ThingsFunctionType } from '@common-src/model/enum';
import { ICRUDQ } from '@common-src/model/interface';
import { SimpleTreeModel } from '@common-src/model/simple-tree-model';
import { ItemStyleMixin } from 'echarts/lib/model/mixin/itemStyle';
import { get, post, put, del } from './request';

const URL_PATH = `${IOT_BASE_REQUEST_PATH}/product/template`;

class ThingsTemplateService implements ICRUDQ<ThingsTemplateEntityModel, ThingsTemplateQueryModel> {
    async create(model: ThingsTemplateEntityModel): Promise<ThingsTemplateEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string): Promise<ThingsTemplateEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new ThingsTemplateEntityModel().toModel(res);
    }

    async update(model: ThingsTemplateEntityModel): Promise<ThingsTemplateEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: ThingsTemplateEntityModel): Promise<ThingsTemplateEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: ThingsTemplateQueryModel, page?: number, limit?: number): Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ThingsTemplateEntityModel().toModel(item));
        return res;
    }
    async syncQuery(query?: ThingsSyncQueryModel, page?: number, limit?: number): Promise<any> {
        let url = `${IOT_BASE_REQUEST_PATH}/product/sync/compare`;
        const params = Object.assign({
            sourceGroupIds: [],
            sourceName: null,
            targetGroupIds: [],
            targetName: null,
            token: '',
            url: ''
        }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ThingsTemplateEntityModel().toModel(item));
        return res;
    }
    async getSysnGroup(remoteUrl: string, token: string): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/groups/sourceAndTargetGroupList?url=${remoteUrl}&type=1&token=${token}`;
        const res = await get(url);
        return {
            sourceGroups: _.map(_.get(res, 'sourceGroups'), item => {
                return new SimpleTreeModel().toModel(item);
            }),
            targetGroups: _.map(_.get(res, 'targetGroups'), item => {
                return new SimpleTreeModel().toModel(item);
            })
        };
    }

    async sync(
        remoteUrl: string,
        groupId: string,
        token: string,
        syncType: SyncType,
        templateIds: Array<any>,
        check: boolean = true
    ): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/product/sync`;
        const params = {
            url: remoteUrl,
            token,
            syncType,
            groupId,
            templateIds,
            check,
            importStrategy: 0
        };
        const res = await post(url, params);
        return res;
    }
    async getDefaultUrl(): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/product/getDefaultUrl`;
        const res = await get(url);
        return res;
    }

    async getDefaultSyncUrl(): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/product/sync/getDefaultUrl`;
        const res = await get(url);
        return res;
    }

    async getTemplateGroups(body: any): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/product/sync/getTemplateGroups`;
        const res = await post(url, body);
        return _.map(res, item => {
            return new SimpleTreeModel().toModel(item);
        });
    }

    async getSyncTemplateCheck(body: any): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/product/sync/syncProductCheck`;
        const res = await post(url, body);
        return res;
    }

    async syncProduct(
        local: boolean,
        remoteUrl: string,
        token: string,
        templateIds: Array<any>
    ): Promise<any> {
        const url = `${IOT_BASE_REQUEST_PATH}/product/sync/syncProduct`;
        const params = {
            local,
            url: remoteUrl,
            token,
            templateIds
        };
        const res = await post(url, params);
        return res;
    }
}

export default new ThingsTemplateService();
